<template>
  <el-scrollbar :native="false">
    <div
      v-if="formAction"
      style="padding: 20px; max-height: calc(100vh - 40px)"
    >
      <div v-if="multipleItemsSelectedCount" class="side-panel-selected-count">
        <p>
          {{ __("Selected") }}
          <el-tag>
            <strong>{{ multipleItemsSelectedCount }}</strong>
          </el-tag>
          {{ __("items") }}.
        </p>
      </div>
      <div v-else class="side-panel-content-info">
        <div class="field-group">
          <div class="titles">{{ __("ID") }}</div>
          <div class="details">{{ contentForm.data_store_id }}</div>
        </div>
        <div class="field-group">
          <div class="titles">{{ __("Name") }}</div>
          <div class="details">{{ contentForm.display_name }}</div>
        </div>
        <div class="field-group">
          <div class="titles">{{ __("Webhook URL") }}</div>
          <div class="details">{{ contentForm.web_hook_url }}</div>
        </div>
        <div class="field-group">
          <div class="titles">{{ __("Data Retention") }}</div>
          <div class="details">{{ contentForm.data_retention }}</div>
        </div>

        <!--    <el-button plain @click="handleEditBtnClick" class="editBtn" -->
        <!--      >Edit Datastore</el-button-->
        <!--    >-->
        <!--    <el-button plain @click="openMoveToFolderDialog" class="editBtn" >-->
        <!--      Move to folder-->
        <!--    </el-button>-->
      </div>
      <div
        style="display: flex; margin-top: 20px"
        class="side-panel-action-list"
      >
        <el-dropdown @command="handleDatastoreAction" placement="bottom-start">
          <el-button plain class="btn-plain">
            {{ __("Actions") }}
          </el-button>

          <el-dropdown-menu slot="dropdown" style="width: 200px;">
            <el-dropdown-item
              v-for="(command, index) in commandsToShow"
              :key="index"
              :command="command.command"
              v-show="
                (multipleItemsSelectedCount ? command.selectAction : true) ||
                  command.show_at_archive
              "
              :disabled="!can(command.permission)"
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              v-show="!multipleItemsSelectedCount && isArchivedStatus"
              command="restore"
            >
              <span>{{ __("Restore") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-show="!multipleItemsSelectedCount"
              :disabled="!can('content.data-stores.write')"
              command="delete"
            >
              <span>{{ __("Delete") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-dialog
        :visible.sync="openModal"
        v-if="openModal"
        :before-close="notifyClosingOfModal"
        custom-class="editContentItemModal"
        destroy-on-close
        fullscreen
        :show-close="true"
        :modal="false"
      >
        <component
          :datastore="contentForm"
          v-bind:is="component"
          :id="id"
          @cancel="handleCancel"
        ></component>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>

<script>
import _ from "lodash";
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import Copier from "@/components/Copier";
import {
  CHECK_IN_USE_ACTION,
  DELETE_ACTION,
  MOVE_ACTION,
  REFRESH_ACTION,
  RESTORE_ACTION
} from "@/constants/contents";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  name: "DatastoreActions",
  components: {
    Copier,
    PageHeader
  },
  data() {
    return {
      commands: [
        {
          command: "createOrEditForm",
          rules: {},
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit"),
          selectAction: false,
          permission: "content.data-stores.write",
          show_at_archive: false
        },
        {
          command: "viewData",
          rules: {},
          icon: "campaign_actions/icon_view_data.svg",
          label: __("View Data"),
          selectAction: false,
          permission: "content.data-stores.read",

          show_at_archive: true
        },
        {
          command: "uploadData",
          rules: {},
          icon: "campaign_actions/icon_upload.svg",
          selectAction: false,
          label: __("Upload Data"),
          permission: "content.data-stores.write",
          show_at_archive: false
        },
        {
          command: "check_in_use",
          rules: {},
          icon: "icon-search.svg",
          selectAction: false,
          label: __("Check in use"),
          permission: "content.data-stores.read",
          show_at_archive: true
        },
        {
          command: "refresh",
          rules: {},
          icon: "icon-search.svg",
          selectAction: false,
          label: __("Refresh check in use"),
          permission: "content.data-stores.read",
          show_at_archive: true
        },
        {
          command: "move",
          rules: {},
          icon: "move-phone-numbers.svg",
          selectAction: true,
          label: __("Move to folder"),
          permission: "content.data-stores.write",

          show_at_archive: false
        }
      ],
      rules: {},
      selectedAction: "createOrEditForm",
      additionalValidateRoute: "data-stores",
      isContent: true,
      forceDeleteEventName: "initiate-force-delete-DataStore",
      safeDeleteEventName: "initiate-safe-delete-DataStore"
    };
  },
  mounted() {},

  methods: {
    ...mapActions("dataStores", {
      createDataStore: "createDataStore",
      updateDataStore: "updateDataStore",
      updateContent: "updateDataStore",
      deleteContentMethod: "deleteDataStore",
      undoDeleteContent: "undoDeleteDataStore",
      safeDeleteContent: "safeDeleteDataStore",
      refreshDataStore: "refreshDataStore"
    }),
    // getIconSVG(name) {
    //   return require("@/assets/icons/" + name);
    // },
    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "DataStore",
        content_id: this.contentForm.data_store_id
      });
    },
    refreshCheckInUse() {
      // call backend api
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "DataStore",
        content_id: this.contentForm.data_store_id,
        content_name: this.contentForm.display_name,
        refresh: this.refreshDataStore
      });
    },
    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.data_store_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.data_store_id) {
            return item.data_store_id;
          }
        });
      }

      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "DataStore"
      });
    },
    handleDatastoreAction(action) {
      switch (action) {
        case DELETE_ACTION:
          this.handleDelete(
            this.contentForm,
            "DataStore",
            this.contentForm.data_store_id
          );
          break;
        case CHECK_IN_USE_ACTION:
          this.openCheckInUseDialog();
          break;
        case REFRESH_ACTION:
          this.refreshCheckInUse();
          break;
        case MOVE_ACTION:
          this.openMoveToFolderDialog();
          break;
        case RESTORE_ACTION:
          this.restoreContent(this.contentForm);
          break;
        default:
          if (["createOrEditForm"].includes(action)) {
            this.$emit("edit-item");
          } else if (["viewData", "uploadData"].includes(action)) {
            this.$emit("extend-item");
          }
          this.selectedAction = action;
          this.handleOpenModal(true);
          break;
      }
    },
    importComponent(path) {
      return () => import("@/views/build/content/datastores/pages/" + path);
    }
  },
  computed: {
    component() {
      return this.importComponent(this.selectedAction);
    },
    toggleOptionsVisibility(commandVisibility) {
      if (this.multipleItemsSelectedCount) {
        return commandVisibility;
      }
      return true;
    },
    commandsToShow() {
      return _.filter(
        _.map(this.commands, command => {
          return {
            ...command,
            show_at_archive:
              !this.multipleItemsSelectedCount && command.show_at_archive
          };
        }),

        command => {
          if (_.isEmpty(command.rules)) {
            return true;
          }
          return _.every(command.rules, (rule, key) => {
            return rule.includes(this.contentForm[key].toLowerCase());
          });
        }
      );
    }
  },
  watch: {
    contentForm: {
      handler: function() {
        // this.setSelectedPoolId(pool.pool_id);
      }
    },
    formAction: {
      immediate: true,
      handler: function(val) {
        if (val) {
          if (val === "view") {
            this.selectedAction = "viewData";
          }
          if (val === "edit" || val === "add") {
            this.selectedAction = "createOrEditForm";
          }
        }
      }
    }
  },
  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });

    EventBus.$on(this.safeDeleteEventName, ({ content }) => {
      this.initiateSafeDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
    EventBus.$off(this.safeDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";

.api-key-item {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .api-key {
    word-break: break-word;
    padding-right: 5px;
  }
}

.campaignActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
<style lang="scss">
.editContentItemModal {
  padding: 0;
  border-radius: 0;
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
