<template>
  <div>
    <div v-if="contentViewType === 'list'" :style="{ width: itemWidth }">
      <el-row
        v-if="itemIndex === 0"
        type="flex"
        :gutter="10"
        class="content-list-row content-list-row-header"
      >
        <el-col :span="1">
          <el-checkbox
            ref="cardMultiSelect"
            class="content-list-checkbox"
            v-if="allowMultiSelect"
            v-model="isAllChecked"
            @change="$emit('toggle-select-all')"
          ></el-checkbox>
        </el-col>
        <el-col :span="23">
          <el-row type="flex" :gutter="10">
            <el-col
              v-for="(name, index) in fieldDisplayNames"
              :key="'content-list-field-' + index"
              :span="index === 0 ? 8 : 6"
              :class="
                `content-list-item-header content-width ${
                  index === 0 ? 'name' : ''
                }`
              "
            >
              {{ name }}
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <drag
        class="drag"
        :data="draggableData"
        :disabled="currentTab === 'tasks'"
      >
        <template v-slot:drag-image v-if="allowDrag && isChecked">
          <div v-if="isChecked" class="drag-img">
            <div>
              <div class="content-icon">
                <img :src="getCardIcon" alt="icon-content" />
              </div>
            </div>
            <div>
              {{
                __(":totalItemsSelected items selected", {
                  totalItemsSelected: totalItemsSelected
                })
              }}
            </div>
          </div>
        </template>

        <div
          @click.stop="$emit('select')"
          @dblclick.stop="$emit(doubleClickAction, $event)"
        >
          <el-row
            ref="contentListRow"
            type="flex"
            :gutter="10"
            :class="cardSelectedClass"
            class="content-list-row"
          >
            <el-col :span="1">
              <el-checkbox
                ref="cardMultiSelect"
                class="content-list-checkbox"
                v-if="allowMultiSelect"
                v-model="isChecked"
                @change="$emit('checked-content', $event)"
              ></el-checkbox>
            </el-col>
            <el-col :span="23">
              <el-row type="flex" :gutter="10">
                <el-col
                  v-for="(field, index) in fieldsToDisplay"
                  :key="'content-list-item-' + index"
                  :class="
                    `content-list-item content-width ${
                      index === 0 ? 'name' : ''
                    }`
                  "
                  :span="index === 0 ? 8 : 6"
                >
                  <div class="flex" :class="'content-item-width'">
                    <div class="text-wrap-anywhere" :class="field.field_name">
                      {{ contentListItem[field.field_name] }}
                    </div>
                    <slot v-if="field.use_slot" :name="field.field_name"></slot>
                  </div>
                </el-col>
                <el-col
                  v-if="showViewData"
                  style="align-self: center; margin-left: -50px;"
                  :span="2"
                >
                  <div class="content-actions-button">
                    <div class="view-data-button" @click="$emit('view')">
                      {{ __("View Data") }}
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </drag>
    </div>
    <div v-else>
      <content-card
        :draggable-data="draggableData"
        :allow-multi-select="allowMultiSelect"
        :card-class="cardClass"
        :icon="icon"
        :card-text="cardText"
        :show-view-data="showViewData"
        @delete="$emit('delete')"
        @select="$emit('select')"
        @edit="$emit(doubleClickAction, $event)"
        @checked-content="$emit('checked-content', $event)"
        @view="$emit('view')"
        :total-items-selected="totalItemsSelected"
      ></content-card>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { Drag } from "vue-easy-dnd";
import { mapState } from "vuex";
import _ from "lodash";
import ContentCard from "@/views/build/content/mixins/ContentCard";

export default {
  name: "ContentListItem",
  components: {
    Drag,
    ContentCard
  },
  data() {
    return {
      isChecked: false,
      dragDisabled: true,
      isAllChecked: false,
      itemWidth: "auto"
    };
  },
  props: {
    contentViewType: {
      type: String,
      default: "card"
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    cardClass: {
      type: Object,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: "icon-microphone.svg"
    },
    cardText: {
      type: String,
      required: true
    },
    fieldsToDisplay: {
      type: Array,
      default: null
    },
    contentListItem: {
      type: Object,
      required: true
    },
    doubleClickAction: {
      type: String,
      required: false,
      default: "edit"
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showViewData: {
      type: Boolean,
      required: false,
      default: false
    },
    deleteTooltip: {
      type: String,
      required: false,
      default: __("Delete")
    },
    allowMultiSelect: {
      default: false,
      type: Boolean
    },
    draggableData: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowDrag: {
      default: true,
      type: Boolean
    },
    totalItemsSelected: {
      default: 0
    },
    isAllSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("folders", {
      currentTab: state => state.currentTab
    }),

    getCardIcon() {
      let icon = "";
      try {
        icon = require("@/assets/icons/content-" + this.icon);
      } catch (e) {
        icon = require("@/assets/icons/" + this.icon);
      }
      return icon;
    },

    cardSelectedClass() {
      let className = "";
      if (this.cardClass && this.cardClass.selected === true) {
        className = "selected";
      }

      if (this.isChecked === true) {
        className += " checked";
      }
      return className;
    },

    fieldDisplayNames() {
      return _.map(this.fieldsToDisplay, "display_name");
    }
  },
  methods: {
    clearCheckedItems() {
      this.isChecked = false;
    },

    checkItems() {
      this.isChecked = true;
    },

    updateScrollbar() {
      if (this.contentViewType === "list" && this.showViewData) {
        let widthRatio =
          (this.$refs.contentListRow.$el.scrollWidth * 100) /
          this.$refs.contentListRow.$el.clientWidth;
        if (widthRatio > 100) {
          this.itemWidth = widthRatio + "%";
        } else {
          this.itemWidth = "auto";
        }
      }
    }
  },
  watch: {
    isAllSelected: {
      immediate: true,
      handler: function(val) {
        this.isAllChecked = val;
        if (val) {
          EventBus.$emit("select-all");
        } else {
          EventBus.$emit("de-select-all");
        }
      }
    }
  },
  mounted() {
    EventBus.$on("de-select-all", this.clearCheckedItems);
    EventBus.$on("select-all", this.checkItems);
    this.$nextTick(this.updateScrollbar);
    window.addEventListener("resize", this.updateScrollbar);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScrollbar);
    EventBus.$off("de-select-all", this.clearCheckedItems);
    EventBus.$off("select-all", this.checkItems);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$content-theme-hover-background-color: var(
  --theme-hover-background-color
) !default;
$site-secondary-background-color: var(
  --site-secondary-background-color
) !default;
$content-theme-tag-border-color: var(--theme-tag-border-color) !default;
.content-list-row {
  padding: 8px 10px;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px $site-secondary-background-color;

  &.selected {
    background-color: $content-theme-tag-border-color;
  }

  &.checked {
    background-color: $content-theme-row-hover-color;
  }
  &:hover {
    background-color: $content-theme-hover-background-color;
  }

  .content-width {
    max-width: 300px;

    .content-item-width {
      max-width: 290px;
    }
  }

  .name {
    flex-grow: 1;
  }

  .content-list-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #454545;
    display: flex;
    align-items: center;

    .text-wrap-anywhere {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: unset;
      white-space: unset;
    }
  }

  .content-list-item-header {
    font-weight: 600;
    font-size: 14px;
    color: #696969;
  }

  ::v-deep .content-list-checkbox {
    .el-checkbox__input {
      &.is-checked {
        .el-checkbox__inner {
          background-color: $content-theme-color;
          border-color: $content-theme-color;
        }
      }
      .el-checkbox__inner:hover {
        border-color: $content-theme-color;
      }
    }
  }

  .content-actions-button {
    display: flex;
    width: 120px;
    justify-content: center;

    .view-data-button {
      color: var(--theme-color);
      font-size: 13px;
      text-decoration: none;
      font-weight: bold;
    }

    .view-data-button:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.content-list-row-header:hover {
  background-color: transparent;
}

.drag {
  transition: all 0.5s;
}

.drag-img {
  background-color: transparent;
}
</style>
