<template>
  <div
    class="content-component"
    @click="handleClearSelection"
    style="width: 100%;"
  >
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create Datastore") }}</el-button
    >
    <div style="width: 100%" v-loading="loading">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            :show-content-view-switcher="true"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="false">
          <div class="contentCardsContainer">
            <el-row
              :class="contentViewClasses"
              v-if="dataStores.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(dataStore, index) in dataStores"
                :item-index="index"
                :key="'_content_data_store_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [dataStore[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="canDelete(dataStore)"
                :card-class="{ selected: selectedItemIndex === index }"
                :showViewData="true"
                icon="icon-datastore.svg"
                :card-text="dataStore.display_name"
                :show-delete="canDelete(dataStore)"
                @delete="
                  handleDelete(dataStore, 'DataStore', dataStore.data_store_id)
                "
                :content-list-item="dataStore"
                :fields-to-display="displayFields"
                @select="handleSelect(index, dataStore)"
                @edit="handleEdit(index, dataStore)"
                @view="handleView(index, dataStore)"
                @checked-content="handleSelectMultiple($event)(dataStore)"
                :total-items-selected="selectedItems.length"
                @toggle-select-all="
                  handleToggleSelectAll(dataStores, ...arguments)
                "
                :is-all-selected="isAllSelected"
              ></content-list-item>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No Datastores") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";

export default {
  name: "DataStoresList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem
  },
  data() {
    return {
      additionalValidateRoute: "data-stores",
      sortByColumns: ["display_name", "data_store_id"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "display_name",
          display_name: __("Name")
        },
        {
          field_name: "web_hook_url",
          display_name: __("Webhook URL")
        },
        {
          field_name: "data_retention",
          display_name: __("Data Retention")
        }
      ]
    };
  },

  computed: {
    ...mapState("dataStores", {
      dataStores: state => state.dataStores,
      loading: state => state.loading
    }),
    canDelete() {
      return dataStore => {
        return !dataStore.is_log;
      };
    },
    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    }
  },

  methods: {
    ...mapActions("dataStores", {
      contentAPI: "getDataStoresForList"
    })
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: #4db3f6 !default;
$content-theme-hover-color: dodgerblue !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
