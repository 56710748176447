<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/DatastoreActions";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "DataStoresIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("dataStores", {
      contents: state => state.dataStores
    })
  },

  methods: {
    initContent() {
      return {
        display_name: "",
        web_hook_url: "",
        language_id: "",
        data_retention: "Three Months",
        ac_id: "",
        is_log: false,
        collection_name: "",
        is_archived: 0,
        collection_structure: [
          {
            col_name: "",
            index_type: "index",
            type: "string"
          }
        ]
      };
    }
  },

  mounted() {
    this.contentType = "Datastore";
    this.primaryKey = "data_store_id";
  }
};
</script>

<style lang="scss" scoped></style>
